import { ADSChangeEvent, Col, Radio, RadioGroup } from '@appliedsystems/applied-design-system';
import { PaymentMethod, toIntlFormat } from '@appliedsystems/payments-core';
import React from 'react';
import { currencyMap } from '../../constants/constants';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { Locale } from '../../store/Locale';
import { getAmountWithFees } from '../../util/getAmountWithFees';
import { AvailablePaymentMethod, HppData } from '../HostedPaymentPageContainer/types';
import { useHppDataStore } from '../HostedPaymentPageContainer/useHppData';
import { PaymentMethodDetails } from '../PaymentMethodSelection/PaymentMethodSelection';

type Props = {
  availablePaymentMethods: AvailablePaymentMethod[];
  paymentMethods: PaymentMethodDetails;
  onPaymentMethodSelected: (paymentDetails: Pick<HppData, 'paymentMethod' | 'paymentFee'>) => void;
};

export const PaymentOptionRadioButton = ({
  availablePaymentMethods,
  paymentMethods,
  onPaymentMethodSelected,
}: Props): React.ReactElement => {
  const { t } = usePaymentsTranslation();
  const { locale } = Locale.useContainer();
  const currencyCode = currencyMap[locale];
  const { hppData, paymentMethodConfig } = useHppDataStore();

  return (
    <RadioGroup
      name="paymentMethod"
      label={t('PAYMENT_METHOD')}
      required
      value={hppData.paymentMethod}
      onChange={(event: ADSChangeEvent) => {
        const paymentMethodKey = event.target.value as AvailablePaymentMethod;
        const paymentConfig = paymentMethodConfig[paymentMethodKey];
        if (hppData.paymentMethod !== paymentMethodKey) {
          onPaymentMethodSelected({
            paymentMethod: paymentMethodKey,
            paymentFee: paymentConfig.fee,
          });
        }
      }}
    >
      {availablePaymentMethods.map((paymentMethodKey) => {
        const paymentConfig = paymentMethodConfig[paymentMethodKey];

        if (!paymentConfig.allowed) return <Col key={paymentMethodKey}></Col>; // Not an allowed payment method

        const { paymentFee } = getAmountWithFees(
          paymentMethodKey,
          hppData.paymentAmount,
          paymentConfig.fee,
          currencyMap[locale],
        );

        const getIntlFormattedAmount = (amount: number) =>
          toIntlFormat(
            {
              amount,
              currencyCode,
            },
            locale,
          );

        return (
          <Radio key={paymentMethodKey} value={paymentMethodKey} name="paymentMethod">
            {paymentMethods[paymentMethodKey].title}{' '}
            {[PaymentMethod.Card].includes(paymentMethodKey)
              ? `(${((paymentFee / (hppData.paymentAmount || 1.0)) * 100).toFixed(1)}% fee)`
              : `(${getIntlFormattedAmount(paymentFee)} fee)`}
          </Radio>
        );
      })}
    </RadioGroup>
  );
};
