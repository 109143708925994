import { BrandType, useNotifications } from '@appliedsystems/applied-design-system';
import fetchBuilder from 'fetch-retry';
import React, { useCallback, useEffect, useRef } from 'react';
import { usePaymentsTranslation } from '../../hooks/usePaymentsTranslation';
import { getConfig } from '../../util/config';

const CRITICAL_URLS = [
  `https://checkoutshopper-${getConfig('REACT_APP_ADYEN_ENVIRONMENT')}.adyen.com/`,
  `${getConfig('REACT_APP_PAYMENTS_API_BASE_URL')}/livez`,
  'https://www.google.com/', // required for recaptcha
  'https://www.gstatic.com/recaptcha/api2/logo_48.png', // required for recaptcha
];

const fetch = fetchBuilder(global.fetch, {
  retries: 3,
  retryDelay: 500,
}); // Retry fetches up to 3 times with a 500ms delay

const checkIfBlocked = (url: string) => {
  return fetch(url, { method: 'GET', mode: 'no-cors' })
    .then((_response) => {
      return { isBlocked: false, url }; // Not blocked (the call went through without errors and we don't care about the response)
    })
    .catch((error) => {
      console.error('Network checker error:', { error, url });
      return { isBlocked: true, url }; // Likely blocked or network issue
    });
};

export const NetworkErrorChecker = () => {
  const { addNotification } = useNotifications();
  const { t } = usePaymentsTranslation();

  const notificationShown = useRef(false);

  const handleIsBlocked = useCallback(
    async ({ isBlocked, url }: { isBlocked: boolean; url: string }) => {
      if (isBlocked && !notificationShown.current) {
        const ErrorNotification = (
          <div className="non-closeable-alert">{t('NETWORK_ERROR', undefined, { url } as any)}</div>
        );
        addNotification({
          type: BrandType.Warning,
          content: ErrorNotification,
        });

        notificationShown.current = true;
      }
    },
    [t, addNotification],
  );

  useEffect(() => {
    for (const url of CRITICAL_URLS) {
      void checkIfBlocked(url).then(handleIsBlocked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
