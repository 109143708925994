import { datadogLogs } from '@datadog/browser-logs';
import metaJson from './meta.json';
import { getConfig } from './util/config';

export const oneTimeInit = () => {
  if (getConfig('REACT_APP_DD_CLIENT_TOKEN')) {
    datadogLogs.init({
      clientToken: getConfig('REACT_APP_DD_CLIENT_TOKEN'),
      site: getConfig('REACT_APP_DD_SITE'),
      service: 'payments-ui-flow',
      version: metaJson.version,
      env: getConfig('REACT_APP_TIER'),
      forwardErrorsToLogs: true,
    });
  } else {
    console.info('Skipping DataDog client error logger, REACT_APP_DD_CLIENT_TOKEN not set');
  }
};

// needs to be at top level outside of a function so it runs before the adyen import happens
if (getConfig('REACT_APP_PATCH_ADYEN_CALLS') === 'true') {
  // Adyen doesn't log the errors returned by fetch() when they make API requests
  // we want to see those errors and log them
  // so replace the browser's fetch() with our own which logs errors if it's a request do adyen
  window.originalFetch = window.fetch;
  const replacementFetch: (typeof window)['fetch'] = (
    input: Parameters<(typeof window)['fetch']>[0],
    init?: Parameters<(typeof window)['fetch']>[1],
  ): ReturnType<(typeof window)['fetch']> => {
    try {
      const url = typeof input === 'string' ? input : 'url' in input ? input.url : `${input}`;
      if (!url.includes('adyen.com')) return window.originalFetch!(input, init);

      return window.originalFetch!(input, init)
        .then(async (response) => {
          // We get opaque responses from the network error checker so its not an error
          if (!response.ok && !response.type.includes('opaque')) {
            console.error('Error during Adyen fetch() request', response);
            console.error('Adyen fetch() response body: ', await response.clone().text());
            console.error('Adyen fetch() input: ', input);
            console.error('Adyen fetch() init: ', init);
          }
          return response;
        })
        .catch((err) => {
          console.error('Error during Adyen fetch() request', err);
          console.error('Adyen fetch() input: ', input);
          console.error('Adyen fetch() init: ', init);
          throw err;
        });
    } catch (err) {
      console.error('error patching fetch()', err);
      return window.originalFetch!(input, init);
    }
  };
  window.fetch = replacementFetch;
}
