import {
  Button,
  DataGrid,
  DropdownButton,
  H2,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
} from '@appliedsystems/applied-design-system';
import { CountryCode, StoredPaymentMethod } from '@appliedsystems/payments-core';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { ApiClient } from '../../../api/ApiClient';
import { useDisableStoredPaymentMethod } from '../../../hooks/useDisableStoredPaymentMethod';
import { usePaymentsTranslation } from '../../../hooks/usePaymentsTranslation';
import { getConfig } from '../../../util/config';
import { AddPaymentMethodModal } from './AddPaymentMethodModal';
import { EditPaymentMethodModal } from './EditPaymentMethodModal';
import styles from './PaymentMethods.module.scss';

// generates 3 undefined rows to display placeholder rows while loading
const LOADING_ROWS = Array.from({ length: 3 }, () => undefined);

type Props = {
  paymentSessionToken: string;
};

export const PaymentMethods = ({ paymentSessionToken }: Props) => {
  const { t } = usePaymentsTranslation();
  const [paymentMethodToAdd, setPaymentMethodToAdd] = useState<'scheme' | 'ach' | null>(null);
  const [paymentMethodToEdit, setPaymentMethodToEdit] = useState<StoredPaymentMethod | undefined>();
  const {
    data: paymentMethods,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['paymentMethods'],
    queryFn: async () => {
      const response = await ApiClient.getInstance().getStoredPaymentMethods();
      if (response.status !== 'ok' || !response.data) {
        console.error(`Invalid response from server`, response);
        throw new Error(`Invalid response from server`);
      }

      return response.data;
    },
    refetchInterval: 30 * 1000,
  });
  const { onDisableStoredPaymentMethod, isLoading: isDeleteLoading } = useDisableStoredPaymentMethod();
  const [paymentMethodToDelete, setPaymentMethodToDelete] = useState<StoredPaymentMethod | undefined>();

  const onConfirmDeletePaymentMethod = () => {
    if (!paymentMethodToDelete) {
      return;
    }
    void onDisableStoredPaymentMethod(paymentMethodToDelete.recurringDetailReference, () => {
      setPaymentMethodToDelete(undefined);
    });
  };

  const onCancelDeletePaymentMethod = () => {
    setPaymentMethodToDelete(undefined);
  };

  const onSuccessAddPaymentMethod = () => {
    setPaymentMethodToAdd(null);
    void refetch();
  };

  const noDataMessage = <i>{t('NO_DATA')}</i>;

  const countryCode = getConfig('REACT_APP_LOCALE').toUpperCase();

  return (
    <div>
      <div className="mb-200">
        <div className={styles.titleWrapper}>
          <H2 underline={false} className="mb-100">
            {t('CREDIT_CARDS')}
          </H2>
          <Button onClick={() => setPaymentMethodToAdd('scheme')} type="primary">
            <Icon className={styles.buttonIcon} icon="AddIcon" size={18} />
            {t('CREDIT_CARD')}
          </Button>
        </div>
        <DataGrid
          columns={[
            {
              name: 'paymentMethodNickname',
              title: t('PAYMENT_METHOD_ACCOUNT_NICKNAME'),
              cellRenderer: (row: StoredPaymentMethod) => row.paymentMethodNickname || <i>No Nickname</i>,
            },
            {
              name: 'cardNumber',
              title: t('PAYMENT_METHOD_CARD_NUMBER'),
              cellRenderer: (row: StoredPaymentMethod) => (row.card?.number ? `*** *** *** ${row.card.number}` : ''),
            },
            {
              name: 'expiry',
              title: t('PAYMENT_METHOD_EXPIRY'),
              cellRenderer: (row: StoredPaymentMethod) => {
                const { expiryMonth, expiryYear } = row.card ?? {};
                if (!expiryMonth || !expiryYear) {
                  return '';
                }
                return `${expiryMonth.toString().padStart(2, '0')}/${expiryYear}`;
              },
            },
            {
              name: 'type',
              title: t('PAYMENT_METHOD_CARD_TYPE'),
              cellRenderer: (row: StoredPaymentMethod) => row.variant ?? noDataMessage,
            },
            {
              name: 'actions',
              title: '',
              isSortable: false,
              width: '270px',
              cellRenderer: (row: StoredPaymentMethod) => (
                <DropdownButton label={t('EDIT_STORED_PAYMENT_METHOD')}>
                  <Button onClick={() => setPaymentMethodToEdit(row)}>
                    {t('EDIT_STORED_PAYMENT_METHOD_NICKNAME')}
                  </Button>
                  <Button onClick={() => setPaymentMethodToDelete(row)}>{t('REMOVE_PAYMENT_METHOD')}</Button>
                </DropdownButton>
              ),
            },
          ]}
          rows={isLoading ? LOADING_ROWS : paymentMethods?.cards ?? []}
        />
      </div>

      {countryCode !== CountryCode.CA && (
        <div className="mb-100">
          <div className={styles.titleWrapper}>
            <H2 underline={false} className="mb-100">
              {t('ACH')}
            </H2>
            <Button onClick={() => setPaymentMethodToAdd('ach')} type="primary">
              <Icon className={styles.buttonIcon} icon="AddIcon" size={18} />
              {t('ACH')}
            </Button>
          </div>
          <DataGrid
            columns={[
              {
                name: 'paymentMethodNickname',
                title: t('PAYMENT_METHOD_ACCOUNT_NICKNAME'),
                cellRenderer: (row: StoredPaymentMethod) => row.paymentMethodNickname || <i>No Nickname</i>,
              },
              {
                name: 'accountNumber',
                title: t('PAYMENT_METHOD_ACCOUNT_NUMBER'),
                cellRenderer: (row: StoredPaymentMethod) => row.bank?.bankAccountNumber ?? '',
              },
              {
                name: 'actions',
                title: '',
                isSortable: false,
                width: '270px',
                cellRenderer: (row: StoredPaymentMethod) => (
                  <DropdownButton label={t('EDIT_STORED_PAYMENT_METHOD')}>
                    <Button onClick={() => setPaymentMethodToEdit(row)}>
                      {t('EDIT_STORED_PAYMENT_METHOD_NICKNAME')}
                    </Button>
                    <Button onClick={() => setPaymentMethodToDelete(row)}>{t('REMOVE_PAYMENT_METHOD')}</Button>
                  </DropdownButton>
                ),
              },
            ]}
            rows={isLoading ? LOADING_ROWS : paymentMethods?.ach ?? []}
          />

          <Modal
            open={!!paymentMethodToDelete}
            onClose={onCancelDeletePaymentMethod}
            title={t('DELETE_PAYMENT_METHOD_CONFIRMATION_TITLE')}
          >
            <ModalBody>{t('DELETE_PAYMENT_METHOD_CONFIRMATION')}</ModalBody>
            <ModalFooter>
              <Button type="tertiary" onClick={onCancelDeletePaymentMethod}>
                {t('CANCEL')}
              </Button>
              <Button
                isLoading={isDeleteLoading}
                type="primary"
                className="ml-100"
                onClick={onConfirmDeletePaymentMethod}
              >
                {t('DELETE')}
              </Button>
            </ModalFooter>
          </Modal>

          <EditPaymentMethodModal
            storedPaymentMethod={paymentMethodToEdit}
            onClose={() => setPaymentMethodToEdit(undefined)}
          />
          <AddPaymentMethodModal
            paymentMethod={paymentMethodToAdd}
            onClose={() => setPaymentMethodToAdd(null)}
            onSuccess={onSuccessAddPaymentMethod}
            paymentSessionToken={paymentSessionToken}
          />
        </div>
      )}
    </div>
  );
};
